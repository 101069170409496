import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, Box, Button, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import LoadingMask from "../components/LoadingMask";
import { inserisciDettagli } from "../services/api";
import NumberInputCustom from "../components/NumberInputCustom";
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorDialog from "../components/ErrorDialog";

const Dettagli = ({ categorieProdotto, checkRichiesta, data }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [nMesi, setNMesi] = useState(data.durata_mesi);
    const [dettagli, setDettagli] = useState([{
        id_categoria_prodotto: null,
        quantita: 0,
        codice_prodotto: null,
        produttore: null,
        descrizione: null,
        importo: 0
    }]);
    const [datiAzienda, setDatiAzienda] = useState({
        ragione_sociale: null,
        piva: null,
        cf: null,
        sede_indirizzo: null,
        sede_civico: null,
        sede_cap: null,
        sede_citta: null,
        sede_provincia: null,
        locazione_indirizzo: null,
        locazione_civico: null,
        locazione_cap: null,
        locazione_citta: null,
        locazione_provincia: null,
        mail: null,
        pec: null,
        telefono: null
    });

    useEffect(() => {
        if(data.azienda)
        {
            setDatiAzienda(data.azienda)
        }

        if(data.dettagli)
        {
            setDettagli(data.dettagli)
        }
    }, [data.azienda, data.dettagli]);

    const handleClickAggiungi = () => {
        let tmpDettagli = [
            ...dettagli
        ];

        tmpDettagli.push({
            id_categoria_prodotto: null,
            quantita: 0,
            codice_prodotto: null,
            produttore: null,
            descrizione: null,
            importo: 0
        })

        setDettagli(tmpDettagli)
    }

    const handleChangeItem = (item, index) => {
        let tmpDettagli = [
            ...dettagli
        ];

        tmpDettagli[index] = item;

        setDettagli(tmpDettagli)
    }

    const handleClickElimina = (index) => {
        let tmpDettagli = [
            ...dettagli
        ];

        tmpDettagli = tmpDettagli.slice((index + 1));

        setDettagli(tmpDettagli)
    }

    const validateField = (value) => {
        if (!value)
            return true;
        return value.trim() === '';
    };

    const validateNumberField = (value) => {
        if (!value)
            return true;
        return value === 0;
    };

    const handleClickCopiaDati = () => {
        let tmpItem = {
            ...datiAzienda,
            locazione_indirizzo: datiAzienda.sede_indirizzo,
            locazione_civico: datiAzienda.sede_civico,
            locazione_cap: datiAzienda.sede_cap,
            locazione_citta: datiAzienda.sede_citta,
            locazione_provincia: datiAzienda.sede_provincia,
        };

        setDatiAzienda(tmpItem)
    }

    const handleClickConfermaDati = async () => {
        if (!dettagli || dettagli.length === 0) {
            setShowErrorAlert(true);
            setErrorMessage('Inserire almeno un dettaglio del preventivo');
            return;
        }

        let isErrore = false;

        dettagli.forEach((item) => {
            if (!isErrore) {
                if (!item.id_categoria_prodotto || !item.quantita || !item.codice_prodotto || !item.produttore || !item.descrizione || !item.importo) {
                    isErrore = true;
                    setShowErrorAlert(true);
                    setErrorMessage('Compilare correttamente il dettaglio del preventivo');
                    return;
                }
            }
        });

        if (isErrore) {
            return;
        }

        if (!datiAzienda || !datiAzienda.ragione_sociale || !datiAzienda.piva || !datiAzienda.cf || !datiAzienda.mail || !datiAzienda.pec || !datiAzienda.telefono
            || !datiAzienda.sede_indirizzo || !datiAzienda.sede_civico || !datiAzienda.sede_cap || !datiAzienda.sede_citta || !datiAzienda.sede_provincia
            || !datiAzienda.locazione_indirizzo || !datiAzienda.locazione_civico || !datiAzienda.locazione_cap || !datiAzienda.locazione_citta || !datiAzienda.locazione_provincia) {
            setShowErrorAlert(true);
            setErrorMessage('Compilare correttamente il dettagli della tua azienda');
            return;
        }

        let data = {
            dettagli: dettagli,
            azienda: datiAzienda,
            durata_mesi: nMesi
        }

        try {
            setIsLoading(true)
            await inserisciDettagli(data);
            setIsLoading(false);
            checkRichiesta();
        }
        catch (err) {
            console.log(err)
            setIsLoading(false);
            setShowErrorAlert(true);
            setErrorMessage(err);
            return;
        }
    }

    return (
        <Stack style={{ flex: 1 }} alignItems={"center"} spacing={5}>
            <Typography>Conferma il numero di mesi</Typography>

            <Select
                style={{ marginLeft: 20 }}
                value={nMesi}
                label={"Noleggio"}
                onChange={(event) => setNMesi(event.target.value)}>
                <MenuItem value={12}>1 anno</MenuItem>
                <MenuItem value={24}>2 anni</MenuItem>
                <MenuItem value={36}>3 anni</MenuItem>
                <MenuItem value={48}>4 anni</MenuItem>
                <MenuItem value={60}>5 anni</MenuItem>
            </Select>
            <Typography>Inserisci i dettagli del preventivo che ti è stato fatto da Doinn</Typography>
            <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                <Box style={{ width: 20 }} />
                <Button variant="contained" onClick={handleClickAggiungi}>AGGIUNGI RIGA</Button>
                <Box style={{ flex: 1 }} />
                <Button variant="contained" onClick={handleClickConfermaDati}>CONFERMA DATI</Button>
                <Box style={{ width: 20 }} />
            </Box>
            <Accordion style={{ width: '100%' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    Dettagli Preventivo
                </AccordionSummary>

                {
                    dettagli && dettagli.map((item, index) => {
                        return <Box style={{ width: '100%' }} key={index} >
                            <IconButton style={{ marginLeft: 20 }} aria-label="elimina" onClick={() => handleClickElimina(index)}>
                                <DeleteIcon />
                            </IconButton>
                            <Grid container spacing={2} style={{ paddingLeft: 20, paddingTop: 20, paddingBottom: 20, width: '100%' }} key={index}>
                                <Grid item xs={12} sm={3}>
                                    <InputLabel id="comboCategoria" >Categoria Prodotto</InputLabel>
                                    <Select
                                        fullWidth

                                        labelId="comboCategoria"
                                        style={{ minWidth: 180 }}
                                        value={item.id_categoria_prodotto}
                                        onChange={(event) => {
                                            let tmpItem = {
                                                ...item,
                                                id_categoria_prodotto: event.target.value
                                            }
                                            handleChangeItem(tmpItem, index);
                                        }}
                                        error={validateField(item.id_categoria_prodotto)}
                                    >
                                        {categorieProdotto && categorieProdotto.map((cat, indexCat) => (
                                            <MenuItem key={indexCat} value={cat.id}>{cat.descrizione}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={1}></Grid>
                                <Grid item xs={12} sm={3}>
                                    <InputLabel id="produttore" >Produttore</InputLabel>
                                    <TextField
                                        fullWidth
                                        value={item.produttore}
                                        onChange={(event) => {
                                            let tmpItem = {
                                                ...item,
                                                produttore: event.target.value
                                            }
                                            handleChangeItem(tmpItem, index);
                                        }}
                                        error={validateField(item.produttore)}
                                        helperText={validateField(item.produttore) ? 'Questo campo è obbligatorio' : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={1}></Grid>

                                <Grid item xs={12} sm={3}>
                                    <InputLabel id="cod" >Cod. Prodotto</InputLabel>
                                    <TextField
                                        fullWidth

                                        value={item.codice_prodotto}
                                        onChange={(event) => {
                                            let tmpItem = {
                                                ...item,
                                                codice_prodotto: event.target.value
                                            }
                                            handleChangeItem(tmpItem, index);
                                        }}
                                        error={validateField(item.codice_prodotto)}
                                        helperText={validateField(item.codice_prodotto) ? 'Questo campo è obbligatorio' : ''}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={2}>
                                    <InputLabel id="qty" >Quantità</InputLabel>
                                    <NumberInputCustom
                                        labelId="qty"
                                        style={{ justifyContent: 'flex-start' }}
                                        min={0}
                                        value={item.quantita}
                                        onChange={(event, val) => {
                                            let tmpItem = {
                                                ...item,
                                                quantita: val
                                            }
                                            handleChangeItem(tmpItem, index);
                                        }}
                                        error={validateNumberField(item.quantita)}
                                        helperText={validateNumberField(item.quantita) ? 'Questo campo è obbligatorio' : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <InputLabel id="importo" >Importo</InputLabel>
                                    <CurrencyTextField
                                        fullWidth
                                        labelId="importo"
                                        variant="standard"
                                        value={item.importo}
                                        currencySymbol="€"
                                        minimumValue="0"
                                        decimalCharacter="."
                                        digitGroupSeparator=","
                                        onChange={(event) => {
                                            let tmpItem = {
                                                ...item,
                                                importo: parseFloat(event.target.value.replace(/,/g, ''))
                                            }
                                            handleChangeItem(tmpItem, index);
                                        }}
                                        error={validateNumberField(item.importo)}
                                        helperText={validateNumberField(item.importo) ? 'Questo campo è obbligatorio' : ''}
                                    />

                                </Grid>
                                <Grid item xs={12} sm={1}>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <InputLabel id="descrizione" >Descrizione</InputLabel>
                                    <TextField
                                        fullWidth
                                        value={item.descrizione}
                                        onChange={(event) => {
                                            let tmpItem = {
                                                ...item,
                                                descrizione: event.target.value
                                            }
                                            handleChangeItem(tmpItem, index);
                                        }}
                                        error={validateField(item.descrizione)}
                                        helperText={validateField(item.descrizione) ? 'Questo campo è obbligatorio' : ''}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    })
                }
            </Accordion>
            <Box style={{ height: 200 }} />
            <Typography>Inserisci i dettagli della tua azienda</Typography>
            <Grid container spacing={2} style={{ paddingLeft: 20, paddingTop: 20, paddingBottom: 20, width: '100%' }}>
                <Grid item xs={12} sm={3}>
                    <InputLabel id="ragione_sociale" >Ragione Sociale</InputLabel>
                    <TextField
                        labelId="ragione_sociale"
                        fullWidth
                        value={datiAzienda.ragione_sociale}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                ragione_sociale: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.ragione_sociale)}
                        helperText={validateField(datiAzienda.ragione_sociale) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>
                    <InputLabel id="piva" >P. IVA</InputLabel>
                    <TextField
                        labelId="piva"
                        fullWidth
                        value={datiAzienda.piva}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                piva: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.piva)}
                        helperText={validateField(datiAzienda.piva) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>
                    <InputLabel id="cf" >C.F.</InputLabel>
                    <TextField
                        labelId="cf"
                        fullWidth
                        value={datiAzienda.cf}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                cf: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.cf)}
                        helperText={validateField(datiAzienda.cf) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputLabel id="mail" >Email</InputLabel>
                    <TextField
                        labelId="mail"
                        fullWidth
                        value={datiAzienda.mail}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                mail: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.mail)}
                        helperText={validateField(datiAzienda.mail) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputLabel id="pec" >PEC</InputLabel>
                    <TextField
                        labelId="pec"
                        fullWidth
                        value={datiAzienda.pec}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                pec: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.pec)}
                        helperText={validateField(datiAzienda.pec) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>
                    <InputLabel id="telefono" >Telefono</InputLabel>
                    <TextField
                        labelId="telefono"
                        fullWidth
                        value={datiAzienda.telefono}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                telefono: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.telefono)}
                        helperText={validateField(datiAzienda.telefono) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel id="sede_indirizzo" >Indirizzo Sede</InputLabel>
                    <TextField
                        labelId="sede_indirizzo"
                        fullWidth
                        value={datiAzienda.sede_indirizzo}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                sede_indirizzo: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.sede_indirizzo)}
                        helperText={validateField(datiAzienda.sede_indirizzo) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>

                    <InputLabel id="sede_civico" >Civico</InputLabel>
                    <TextField
                        labelId="sede_civico"
                        value={datiAzienda.sede_civico}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                sede_civico: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.sede_civico)}
                        helperText={validateField(datiAzienda.sede_civico) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>

                    <InputLabel id="sede_cap" >CAP</InputLabel>
                    <TextField
                        labelId="sede_cap"
                        value={datiAzienda.sede_cap}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                sede_cap: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.sede_cap)}
                        helperText={validateField(datiAzienda.sede_cap) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputLabel id="sede_citta" >Città</InputLabel>
                    <TextField
                        fullWidth
                        labelId="sede_citta"
                        value={datiAzienda.sede_citta}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                sede_citta: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.sede_citta)}
                        helperText={validateField(datiAzienda.sede_citta) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>

                    <InputLabel id="sede_provincia" >Provincia</InputLabel>
                    <TextField
                        labelId="sede_provincia"
                        value={datiAzienda.sede_provincia}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                sede_provincia: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.sede_provincia)}
                        helperText={validateField(datiAzienda.sede_provincia) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        <Button style={{ marginBottom: 20 }} variant="contained" onClick={handleClickCopiaDati}>COPIA DATI INDIRIZZO LOCAZIONE</Button>
                    </Box>

                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel id="locazione_indirizzo" >Indirizzo Locazione</InputLabel>
                    <TextField
                        labelId="locazione_indirizzo"
                        fullWidth
                        value={datiAzienda.locazione_indirizzo}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                locazione_indirizzo: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.locazione_indirizzo)}
                        helperText={validateField(datiAzienda.locazione_indirizzo) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>

                    <InputLabel id="locazione_civico" >Civico</InputLabel>
                    <TextField
                        labelId="locazione_civico"
                        value={datiAzienda.locazione_civico}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                locazione_civico: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.locazione_civico)}
                        helperText={validateField(datiAzienda.locazione_civico) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>

                    <InputLabel id="locazione_cap" >CAP</InputLabel>
                    <TextField
                        labelId="locazione_cap"
                        value={datiAzienda.locazione_cap}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                locazione_cap: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.locazione_cap)}
                        helperText={validateField(datiAzienda.locazione_cap) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <InputLabel id="locazione_citta" >Città</InputLabel>
                    <TextField
                        fullWidth
                        labelId="locazione_citta"
                        value={datiAzienda.locazione_citta}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                locazione_citta: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.locazione_citta)}
                        helperText={validateField(datiAzienda.locazione_citta) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>

                    <InputLabel id="locazione_provincia" >Provincia</InputLabel>
                    <TextField
                        labelId="locazione_provincia"
                        value={datiAzienda.locazione_provincia}
                        onChange={(event) => {
                            setDatiAzienda({
                                ...datiAzienda,
                                locazione_provincia: event.target.value
                            })
                        }}
                        error={validateField(datiAzienda.locazione_provincia)}
                        helperText={validateField(datiAzienda.locazione_provincia) ? 'Questo campo è obbligatorio' : ''}
                    />
                </Grid>
            </Grid>
            {
                isLoading &&
                <LoadingMask />
            }
            <ErrorDialog showModal={showErrorAlert} setShowModal={setShowErrorAlert} errore={errorMessage} />

        </Stack>
    )
}

export default Dettagli;
