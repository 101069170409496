import React from "react";
import { Box,  Stack, Typography } from "@mui/material";

const DatiInseriti = () => {

    return (
        <Box style={{ flex: 1 }}>
            <Stack alignItems={"center"} spacing={5}>
                <Typography>I dati sono stati inseriti correttamente</Typography>
            </Stack>
        </Box>

    )
}

export default DatiInseriti;
