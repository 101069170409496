import { Box, Button, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import TopBar from "../components/TopBar";
import NumberInputCustom from "../components/NumberInputCustom";
import { nuovaRichiesta } from "../services/api";
import LoadingMask from "../components/LoadingMask";
import ErrorDialog from "../components/ErrorDialog";
import { useNavigate } from "react-router-dom";





const Homepage = () => {
    const navigate = useNavigate();

    const [importo, setImporto] = useState(10000);
    const [mesi, setMesi] = useState(60);
    const [isLoading, setIsLoading] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);


    const handleClickRichiedi = async () => {
        try {
            setIsLoading(true)
            let data = { importo: importo, durata_mesi: mesi };

            if (localStorage.getItem('utente')) {
                let utente = JSON.parse(localStorage.getItem('utente'))
                data = {
                    ...data,
                    id_utente: utente.id
                }
            }
            let response = await nuovaRichiesta(data)
            localStorage.setItem("id_richiesta", response.id);
            navigate('/onboarding');
            setIsLoading(false)
        }
        catch (err) {
            setIsLoading(false)
            setErrorMessage(err);
            setShowErrorAlert(true);
        }
    }


    return (
        <Box>
            <TopBar canLogin={true} />
            <Stack spacing={5}>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center" }} >
                    <Typography style={{width: 300}}>Inserisci l'importo richiesto (€)</Typography>
                    <NumberInputCustom
                        style={{ marginLeft: 20 }}
                        min={0}
                        value={importo}
                        onChange={(event, val) => setImporto(val)} />
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
                    <Typography style={{width: 300}}>Inserisci la durata del noleggio</Typography>
                    <Select
                        style={{ marginLeft: 20 }}

                        value={mesi}
                        label={"Noleggio"}
                        onChange={(event) => setMesi(event.target.value)}>
                        <MenuItem value={12}>1 anno</MenuItem>
                        <MenuItem value={24}>2 anni</MenuItem>
                        <MenuItem value={36}>3 anni</MenuItem>
                        <MenuItem value={48}>4 anni</MenuItem>
                        <MenuItem value={60}>5 anni</MenuItem>
                    </Select>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
                    <Button variant="contained" onClick={handleClickRichiedi}>RICHIEDI PREVENTIVO</Button>
                </Box>
            </Stack>
            {
                isLoading &&
                <LoadingMask />
            }
            <ErrorDialog showModal={showErrorAlert} setShowModal={setShowErrorAlert} errore={errorMessage} />
        </Box>

    )
}

export default Homepage;