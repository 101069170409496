import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Loading = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/homepage');
    }, []);
    return (
        <div className="azl-loading-container">
        </div>
    );
}

export default Loading;