import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Recupera = () => {
  const navigate = useNavigate();
  let { idRichiesta } = useParams();

  useEffect(() => {
    localStorage.setItem("id_richiesta", idRichiesta);
    navigate('/onboarding');
  }, []);

  
  return (
    <div className="azl-loading-container">
    </div>
  );
}

export default Recupera;