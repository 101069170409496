import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel,  OutlinedInput, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import TopBar from "../components/TopBar";
import LoadingMask from "../components/LoadingMask";
import ErrorDialog from "../components/ErrorDialog";
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { nuovaPassword } from "../services/apiUtente";





const ImpostaPassword = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordConferma, setPasswordConferma] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConferma, setShowPasswordConferma] = useState(false);


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPasswordConferma = () => setShowPasswordConferma((show) => !show);


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownPasswordConferma = (event) => {
        event.preventDefault();
    };

    const validateField = (value) => {
        if (!value)
            return true;
        return value.trim() === '';
    };
    const handleClickCambiaPassword = async () => {

        try {
            setIsLoading(true)
            let data = {
                password: password,
                password_confirm: passwordConferma

            }
            let response = await nuovaPassword(data)
            localStorage.setItem("utente", JSON.stringify(response));
            navigate('/homepage');
            setIsLoading(false)
        }
        catch (err) {
            setIsLoading(false)
            setErrorMessage(err);
            setShowErrorAlert(true);
        }
    }


    return (
        <Box>
            <TopBar canHome={true} />
            <Stack spacing={4} style={{ paddingLeft: 20, paddingRight: 20 }}>

                <Typography style={{textAlign: 'center'}}>Imposta la nuova password</Typography>

                <FormControl sx={{ m: 1,}} variant="outlined">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                        id="password"
                        label="Password"
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}
                        error={validateField(password)}
                        helperText={validateField(password) ? 'Questo campo è obbligatorio' : ''}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl sx={{ m: 1,}} variant="outlined">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                        id="password"
                        label="Password"
                        value={passwordConferma}
                        onChange={(event) => {
                            setPasswordConferma(event.target.value)
                        }}
                        error={(passwordConferma !== password)}
                        helperText={(passwordConferma !== password) ? 'Le password sono differenti' : ''}
                        type={showPasswordConferma ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordConferma}
                                    onMouseDown={handleMouseDownPasswordConferma}
                                    edge="end"
                                >
                                    {showPasswordConferma ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
                    <Button variant="contained" onClick={handleClickCambiaPassword}>CAMBIA PASSWORD</Button>
                </Box>
            </Stack>
            {
                isLoading &&
                <LoadingMask />
            }
            <ErrorDialog showModal={showErrorAlert} setShowModal={setShowErrorAlert} errore={errorMessage} />
        </Box>

    )
}

export default ImpostaPassword;