import axios from 'axios';
import { CONFIG } from './config';

const instance = axios.create({
    baseURL: CONFIG.BASE_URL
});

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        return Promise.reject(error);
    }
);

export default instance;