import api from './axiosConfig';

export async function nuovaRichiesta(data) {
    try {
        return await api.post("preventivatore/richiesta", data).then((response) => {
            return response.data.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw error.response.data.message;

        throw error.message;
    }
};

export async function showRichiesta() {
    try {
        if (!localStorage.getItem('id_richiesta')) {
            throw new Error("Richiesta non presente");
        }
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('id_richiesta'),
            },
        }
        return await api.get("preventivatore/richiesta/show", config).then((response) => {
            return response.data.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw error.response.data.message;

        throw error.message;
    }
};

export async function confermaDati() {
    try {
        if (!localStorage.getItem('id_richiesta')) {
            throw new Error("Richiesta non presente");
        }
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('id_richiesta'),
            },
        }
        return await api.post("preventivatore/richiesta/confermaDati", {}, config).then((response) => {
            return response.data.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw error.response.data.message;

        throw error.message;
    }
};

export async function getCategorieProdotti() {
    try {
        if (!localStorage.getItem('id_richiesta')) {
            throw new Error("Richiesta non presente");
        }
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('id_richiesta'),
            },
        }
        return await api.get("preventivatore/richiesta/listaCategorie", config).then((response) => {
            return response.data.results.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw error.response.data.message;

        throw error.message;
    }
};

export async function inserisciDettagli(data) {
    try {
        if (!localStorage.getItem('id_richiesta')) {
            throw new Error("Richiesta non presente");
        }
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('id_richiesta'),
            },
        }
        return await api.post("preventivatore/richiesta/dettagli", data, config).then((response) => {
            return response.data.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw error.response.data.message;

        throw error.message;
    }
};

export async function inserisciPreventivo(formData) {
    try {
        if (!localStorage.getItem('id_richiesta')) {
            throw new Error("Richiesta non presente");
        }
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('id_richiesta'),
                'Content-Type': 'multipart/form-data',
            },
        }
        return await api.post("preventivatore/richiesta/preventivo", formData, config).then((response) => {
            return response.data.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw error.response.data.message;

        throw error.message;
    }
};

export async function getListaDocumenti() {
    try {
        if (!localStorage.getItem('id_richiesta')) {
            throw new Error("Richiesta non presente");
        }
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('id_richiesta'),
            },
        }
        return await api.get("preventivatore/richiesta/listaDocumenti", config).then((response) => {
            return response.data.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw error.response.data.message;

        throw error.message;
    }
};

export async function downloadDocumento(data) {
    try {
        if (!localStorage.getItem('id_richiesta')) {
            throw new Error("Richiesta non presente");
        }
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('id_richiesta'),
            },
            responseType: 'blob'

        }
        return await api.post("preventivatore/richiesta/downloadDocumento",data, config).then((response) => {
            return response.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw error.response.data.message;

        throw error.message;
    }
};


export async function firmaDocumento(data) {
    try {
        if (!localStorage.getItem('id_richiesta')) {
            throw new Error("Richiesta non presente");
        }
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('id_richiesta'),
            },
        }
        return await api.post("preventivatore/richiesta/firmaDocumento", data, config).then((response) => {
            return response.data.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw error.response.data.message;

        throw error.message;
    }
};

export async function rifiutaRichiesta() {
    try {
        if (!localStorage.getItem('id_richiesta')) {
            throw new Error("Richiesta non presente");
        }
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('id_richiesta'),
            },
        }
        return await api.post("preventivatore/richiesta/rifiuta", {}, config).then((response) => {
            return response.data.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw new Error(error.response.data.message);

        throw new Error(error.message);
    }
};

export async function indietroRichiesta() {
    try {
        if (!localStorage.getItem('id_richiesta')) {
            throw new Error("Richiesta non presente");
        }
        const config = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('id_richiesta'),
            },
        }
        return await api.post("preventivatore/richiesta/indietro", {}, config).then((response) => {
            return response.data.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw new Error(error.response.data.message);

        throw new Error(error.message);
    }
};