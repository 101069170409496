import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "./../assets/img/Logo.png"
import { useNavigate } from "react-router-dom";

const TopBar = ({ canHome, canLogin}) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);


    useEffect(() => {
        if(localStorage.getItem('utente'))
        {
            let utente = JSON.parse(localStorage.getItem('utente'))
            setUser(utente.ragione_sociale)
        }
    }, []);

    const handleClickHome = () => {
        localStorage.removeItem('id_richiesta')
        navigate('/homepage');
    }

    const handleClickLogin = () => {
        navigate('/login');
    }

    const handleClickLogout = () => {
        localStorage.removeItem('utente')
        localStorage.removeItem('token')
        localStorage.removeItem('id_richiesta')
        navigate('/login');
    }    

    return <>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100px', width: '100%', marginTop: 0, marginBottom: 50, top: 0, zIndex: 100, borderRadius: 30, backgroundColor: '#00000059' }}>
            <img src={logo} alt="Doinn" width={160} style={{ padding: 5, marginBottom: 5 }} />
            {
                    user &&
                    <Button style={{marginRight: 20}} variant="contained" onClick={handleClickLogout}>Logout</Button>
                }
            <Box style={{flex:1}} >
                {
                    user ?
                    <Typography style={{textAlign: 'center'}}>{user}</Typography>
                    :
                    <></>
                }
                </Box>
            {
                canHome &&
                <>
                    <Button style={{marginRight: 20}} variant="contained" onClick={handleClickHome}>Torna alla Home</Button>
                </>
            }
            {
                canLogin && !user &&
                <>
                    <Button style={{marginRight: 20}} variant="contained" onClick={handleClickLogin}>LOGIN</Button>

                </>
            }
        </Box>
    </>
};

export default TopBar;