import React, { useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, IconButton, InputLabel, Stack, TextField, Typography } from "@mui/material";
import LoadingMask from "../components/LoadingMask";
import { indietroRichiesta, inserisciPreventivo, rifiutaRichiesta } from "../services/api";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

import { styled } from '@mui/material/styles';
import ErrorDialog from "../components/ErrorDialog";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const InserisciPreventivo = ({ data, checkRichiesta }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [flgSDD, setFlgSDD] = useState(false);
    const [iban, setIban] = useState('');
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);



    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === 'application/pdf') {
            setFile(selectedFile)
        }
    };


    const handleClickConferma = async () => {
        if (!file) {
            setShowErrorAlert(true);
            setErrorMessage("Caricare il preventivo");
            return;
        }

        if (flgSDD && !iban) {
            setShowErrorAlert(true);
            setErrorMessage("Indicare l'IBAN per l SDD");
            return;
        }

        try {
            setIsLoading(true)
            let formData = new FormData();
            formData.append("preventivo", file);
            formData.append("flg_sdd", flgSDD);
            if (flgSDD) {
                formData.append("iban", iban);
            }
            await inserisciPreventivo(formData);
            setIsLoading(false);
            checkRichiesta();
        }
        catch (err) {
            console.log(err)
            setIsLoading(false);
            setShowErrorAlert(true);
            setErrorMessage(err);
            return;
        }
    }

    const handleClickRifiuta = async () => {
        try {
            setIsLoading(true)
            await rifiutaRichiesta();
            setIsLoading(false);
            checkRichiesta();
        }
        catch (err) {
            console.log(err)
            setIsLoading(false);
            setShowErrorAlert(true);
            setErrorMessage(err);
            return;
        }
    }

    const handleClickIndietro = async () => {
        try {
            setIsLoading(true)
            await indietroRichiesta();
            setIsLoading(false);
            checkRichiesta();
        }
        catch (err) {
            console.log(err)
            setIsLoading(false);
            setShowErrorAlert(true);
            setErrorMessage(err);
            return;
        }
    }

    
        

    return (
        <Box style={{ flex: 1 }}>
            <Stack alignItems={"center"} spacing={5}>
                {
                    data &&
                    <>
                        <Typography>La rata per la tua richiesta sarebbe di </Typography>
                        <Typography>{data.rataMensile} € </Typography>
                        <Typography>al mese per {data.n_mesi} mesi</Typography>
                    </>
                }
                <Typography>Carica il pdf del preventivo</Typography>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                >
                    Upload file
                    <VisuallyHiddenInput accept="application/pdf" onChange={handleFileChange} type="file" />
                </Button>
                {
                    file &&
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography>{file.name}</Typography>
                        <IconButton style={{ marginLeft: 20 }} aria-label="elimina" onClick={() => setFile(null)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>

                }
                <FormControlLabel control={<Checkbox value={flgSDD} onChange={(event, value) => {
                    setFlgSDD(value)
                }} />} label="SDD" />
                {
                    flgSDD &&
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <InputLabel id="iban" >IBAN</InputLabel>
                        <TextField
                            labelId="iban"
                            value={iban}
                            onChange={(event) => {
                                setIban(event.target.value)

                            }}
                        />
                    </Box>
                }
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
                    <Button variant="contained" onClick={handleClickRifiuta}>RIFIUTA DATI</Button>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
                    <Button variant="contained" onClick={handleClickConferma}>CONFERMA DATI</Button>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
                    <Button variant="contained" onClick={handleClickIndietro}>INDIETRO</Button>
                </Box>
            </Stack>
            {
                isLoading &&
                <LoadingMask />
            }
            <ErrorDialog showModal={showErrorAlert} setShowModal={setShowErrorAlert} errore={errorMessage} />

        </Box>

    )
}

export default InserisciPreventivo;
