import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const RichiestaRifiutata = () => {

    return (
        <Box style={{ flex: 1 }}>
            <Stack alignItems={"center"} spacing={5}>
                <Typography>La richiesta è stata rifiutata.</Typography>
            </Stack>
        </Box>

    )
}

export default RichiestaRifiutata;
