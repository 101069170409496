import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingMask from "../components/LoadingMask";
import { getCategorieProdotti, showRichiesta } from "../services/api";
import ConfermaDati from "./ConfermaDati";
import Dettagli from "./Dettagli";
import InserisciPreventivo from "./InserisciPreventivo";
import DatiInseriti from "./DatiInseriti";
import FirmaDocumenti from "./FirmaDocumenti";
import VisualizzaDocumenti from "./VisualizzaDocumenti";
import RichiestaTerminata from "./RichiestaTerminata";
import RichiestaRifiutata from "./RichiestaRifiutata";

const OnBoarding = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [idStepAttuale, setIdStepAttuale] = useState(null);
    const [idStatoAttuale, setIdStatoAttuale] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [categorieProdotto, setCategorieProdotto] = useState([]);

    const checkRichiesta = async () => {
        try {
            setIsLoading(true);
            let response = await showRichiesta();
            setIdStepAttuale(response.id_step);
            setIdStatoAttuale(response.id_stato);
            setResponseData(response.data)
            setIsLoading(false);

        }
        catch (err) {
            console.log(err)
            navigate('/homepage');
        }
    }

    const getCategorie = async () => {
        try {
            let response = await getCategorieProdotti();
            setCategorieProdotto(response);

        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getCategorie();
        checkRichiesta();
    }, []);

    return (
        <Stack style={{ height: "100vh" }}>
            <TopBar canHome={true} />
            {
                isLoading &&
                <LoadingMask />
            }
            {
                (idStatoAttuale === "RF" || idStatoAttuale === "RC") ?
                    <RichiestaRifiutata />
                    :
                    <>
                        {
                            idStepAttuale &&
                            <>
                                {
                                    idStepAttuale === 1 &&
                                    <ConfermaDati data={responseData} checkRichiesta={checkRichiesta} />
                                }
                                {
                                    idStepAttuale === 2 &&
                                    <Dettagli data={responseData} categorieProdotto={categorieProdotto} checkRichiesta={checkRichiesta} />
                                }
                                {
                                    idStepAttuale === 3 &&
                                    <InserisciPreventivo data={responseData} checkRichiesta={checkRichiesta} />
                                }
                                {
                                    idStepAttuale > 3 && idStepAttuale < 8 &&
                                    <DatiInseriti checkRichiesta={checkRichiesta} />
                                }
                                {
                                    idStepAttuale === 8 &&
                                    <FirmaDocumenti checkRichiesta={checkRichiesta} />
                                }
                                {
                                    (idStepAttuale === 9 || idStepAttuale === 10) &&
                                    <VisualizzaDocumenti checkRichiesta={checkRichiesta} />
                                }
                                {
                                    (idStepAttuale === 11) &&
                                    <RichiestaTerminata checkRichiesta={checkRichiesta} />
                                }
                            </>
                        }
                    </>
            }

        </Stack>

    )
}

export default OnBoarding;
