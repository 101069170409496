import React, { } from "react";
import {
  Routes,
  Route,
  HashRouter,
  Navigate,
} from "react-router-dom";
import Homepage from "../pages/Homepage";
import Loading from "../pages/Loading";
import OnBoarding from "../pages/OnBoarding";
import Recupera from "../pages/Recupera";
import Login from "../pages/Login";
import ImpostaPassword from "../pages/ImpostaPassword";

const MainRoutes = () => {
  return (
    <HashRouter>
      <Routes>
        <Route
          path="/homepage"
          element={
            <Homepage />
          }
        />
        <Route
          path="/login"
          element={
            <Login />
          }
        />
        <Route
          path="/password"
          element={
            <ImpostaPassword />
          }
        />
        <Route
          path="/onboarding"
          element={
            <OnBoarding />
          }
        />
        <Route
          path="/recupera/:idRichiesta"
          element={
            <Recupera />
          }
        />
        <Route path="/" element={<Loading />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </HashRouter>
  );
};

export default MainRoutes;
