import React, { useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import spinner1 from "./../assets/img/loader_dark_1.png"
import spinner2 from "./../assets/img/loader_dark_2.png"
import spinner3 from "./../assets/img/loader_dark_3.png"



const LoadingMask = () => {

    const controls1 = useAnimation();
    const controls2 = useAnimation();
    const controls3 = useAnimation();

    useEffect(() => {
        const animate = async () => {
            controls1.start({ rotate: 360, transition: { duration: 3, repeat: Infinity, ease: "linear" } });
            controls2.start({ rotate: 360, transition: { duration: 2, repeat: Infinity, ease: "linear" } });
            controls3.start({ rotate: 360, transition: { duration: 4, repeat: Infinity, ease: "linear" } });
        };
        animate();
    }, [controls1, controls2, controls3]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 10000,
                backgroundColor: 'rgba(255,255,255, 0.6)',
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    width: 140,
                    height: 140,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    backgroundColor: 'white',
                }}
            >
                <motion.img
                    src={spinner1}
                    style={{ position: 'absolute', width: 90, height: 90 }}
                    animate={controls1}
                />
                <motion.img
                    src={spinner2}
                    style={{ position: 'absolute', width: 90, height: 90 }}
                    animate={controls2}
                />
                <motion.img
                    src={spinner3}
                    style={{ position: 'absolute', width: 90, height: 90 }}
                    animate={controls3}
                />
            </Paper>
        </Box>
    );
};

export default LoadingMask;