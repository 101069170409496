import { Box, Modal, Typography } from "@mui/material";
import React, { } from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function ErrorDialog({ errore, showModal, setShowModal, callback }) {
    const onCloseClick = () => {
        setShowModal(false);
        if (callback) {
            callback();
        }
    }



    return <Modal open={showModal} onClose={onCloseClick}>
        <Box sx={style}>
            {
                errore &&
                <Typography>
                    {errore}
                </Typography>
            }
        </Box>
    </Modal>
};