import React, { useEffect, useState } from "react";
import { Box, Button,  Stack, Tab, Tabs, Typography } from "@mui/material";
import LoadingMask from "../components/LoadingMask";
import { downloadDocumento, firmaDocumento, getListaDocumenti } from "../services/api";
import ErrorDialog from "../components/ErrorDialog";
import { pdfjs, Document, Page } from "react-pdf";
import CheckIcon from '@mui/icons-material/Check';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FirmaDocumenti = ({ checkRichiesta, canBack,callback }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [listaDocumenti, setListaDocumenti] = useState([]);
    const [listaBlobDocumenti, setListaBlobDocumenti] = useState([]);
    const [listaBlobDownloadDocumenti, setListaBlobDownloadDocumenti] = useState([]);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [filePdf, setFilePdf] = useState();
    const [indice, setIndice] = useState(null);

    const getDocumenti = async () => {
        try {
            setIsLoading(true);
            let response = await getListaDocumenti();
            setListaDocumenti(response)
            let tmpDoc = [];
            for (let i = 0; i < response.length; i++) {
                tmpDoc.push(null)
            }
            setListaBlobDocumenti(tmpDoc)
            setListaBlobDownloadDocumenti(tmpDoc)
            setIsLoading(false);

        }
        catch (err) {
            console.log(err)
            setIsLoading(false);
            setShowErrorAlert(true);
            setErrorMessage(err);
        }
    }

    useEffect(() => {
        
        getDocumenti();
    }, []);

    useEffect(() => {
        const getDocumento = async () => {
            let data = {
                id_documento: listaDocumenti[indice].id
            }
            setIsLoading(true);

            let res = await downloadDocumento(data);
            let tmpDownload = [
                ...listaBlobDownloadDocumenti
            ]

            tmpDownload[indice] = res;
            setListaBlobDownloadDocumenti(tmpDownload)
            var reader = new FileReader();
            reader.readAsDataURL(res);
            reader.onloadend = function () {
                var base64data = reader.result;
                setFilePdf(base64data);
                var tmpBlob = [
                    ...listaBlobDocumenti
                ];
                tmpBlob[indice] = base64data;

                setListaBlobDocumenti(tmpBlob)
                setIsLoading(false);
            };
        }
        if (indice !== null) {
            if (!listaBlobDocumenti[indice]) {
                getDocumento()
            }
            else
            {
                setFilePdf(listaBlobDocumenti[indice]);
            }
        }
    }, [indice]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleClickScarica = () => {
        let base64 = listaBlobDownloadDocumenti[indice];
        const blob = new Blob([base64], {
            type: "application/pdf",
          });
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${listaDocumenti[indice].titolo}.pdf`;
        alink.click();
    }

    const handleClickFirma = async() => {
        try
        {
            setIsLoading(true)
            let data = {
                id_documento: listaDocumenti[indice].id
            }
            let response = await firmaDocumento(data);
            setIsLoading(false);
            if(response && response.end)
            {
                checkRichiesta()
                return;
            }
            getDocumenti();
        }
        catch(err)
        {
            console.log(err)
                setIsLoading(false);
                setShowErrorAlert(true);
                setErrorMessage(err);
        }
    }

    const handleClickBack = async() => {
        if(callback)
        {
            callback();
        }
    }

    return (
        <Box style={{ flex: 1 }}>
            {
                canBack &&
                    <Button style={{ marginLeft: 20, marginTop: 20}} variant="contained" onClick={handleClickBack}>Indietro</Button>
            }

            <Tabs style={{ marginLeft: 20, marginTop: 40 }} value={indice} onChange={(event, val) => setIndice(val)}>
                {
                    listaDocumenti && listaDocumenti.map((item, index) => {
                        return <Tab key={index} label={
                            <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            {
                                item.flg_firmato ?
                                <CheckIcon color="green" style={{marginRight: 10}}/>
                                :
                                <></>
                            }
                            {item.titolo}
                            </Box>
                        } {...a11yProps(index)} />
                    })
                }
            </Tabs>
            {
                filePdf &&
                <Stack alignItems={"center"} spacing={5}>
                    <Stack direction={"row"} alignItems={"center"} style={{marginTop: 20, width: '95%'}}>
                        <Button variant="contained" onClick={handleClickScarica}>Scarica Documento</Button>
                        <Box style={{ flex: 1 }} />
                        {
                            !listaDocumenti[indice].flg_firmato ?
                                <Button variant="contained" onClick={handleClickFirma}>FIRMA</Button>
                                :
                                <Typography>Documento già firmato</Typography>
                        }


                    </Stack>
                    <Document
                        file={filePdf}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.from({ length: numPages }, (_, index) => (
                            <Page
                                wrap={false}
                                width={800}
                                key={`page_${index + 1}`}
                                pageIndex={index}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                            >
                                <br />
                            </Page>
                        ))}
                    </Document>
                </Stack>
            }
            {
                isLoading &&
                <LoadingMask />
            }
            <ErrorDialog showModal={showErrorAlert} setShowModal={setShowErrorAlert} errore={errorMessage} />

        </Box>

    )
}

export default FirmaDocumenti;
