import './App.css';
import MainRoutes from './components/MainRoutes';

function App() {
  return (
    <MainRoutes />
  );
}

export default App;
