import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import FirmaDocumenti from "./FirmaDocumenti";

const RichiestaTerminata = ({ checkRichiesta }) => {
    const [showDocumenti, setShowDocumenti] = useState(false);

    const backDocumenti = () => {
        setShowDocumenti(false)
    }

    

    return (
        <Box style={{ flex: 1 }}>
            {
                showDocumenti ?
                    <FirmaDocumenti canBack={true} callback={backDocumenti} checkRichiesta={checkRichiesta} />
                    :
                    <Stack alignItems={"center"} spacing={5}>
                        <Typography>La richiesta è terminata.</Typography>
                        <Typography>Premi il pulsante sottostante per visualizzare i documenti</Typography>
                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
                            <Button variant="contained" onClick={() => setShowDocumenti(true)}>Visualizza Documenti</Button>
                        </Box>
                    </Stack>

            }


        </Box>

    )
}

export default RichiestaTerminata;
