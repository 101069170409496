import api from './axiosConfig';

export async function login(data) {
    try {
        return await api.post("preventivatore/admin/login", data).then((response) => {
            return response.data.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw error.response.data.message;

        throw error.message;
    }
};

export async function nuovaPassword(data) {
    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('token'),
        },
    }
    try {
        return await api.post("preventivatore/admin/newPassword", data, config).then((response) => {
            return response.data.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw error.response.data.message;

        throw error.message;
    }
};

export async function check() {
    const config = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('token'),
        },
    }
    try {
        return await api.post("preventivatore/admin/check", {}, config).then((response) => {
            return response.data.data;
        });
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message)
            throw error.response.data.message;

        throw error.message;
    }
};