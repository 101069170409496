import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingMask from "../components/LoadingMask";
import { confermaDati } from "../services/api";

const ConfermaDati = ({ data, checkRichiesta }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleClickConferma = async () => {
        try
        {
            setIsLoading(true)
            await confermaDati();
            setIsLoading(false);
            checkRichiesta();
        }
        catch(err)
        {
            navigate('/homepage');
        }
    }

    return (
        <Box style={{ flex: 1 }}>
            {
                data &&
                <Stack alignItems={"center"} spacing={5}>
                    <Typography>La rata per la tua richiesta sarebbe di </Typography>
                    <Typography>{data.rataMensile} € </Typography>
                    <Typography>al mese per {data.n_mesi} mesi</Typography>
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
                        <Button variant="contained" onClick={handleClickConferma}>CONFERMA PREVENTIVO</Button>
                    </Box>
                </Stack>
            }
            {
                isLoading &&
                <LoadingMask />
            }
        </Box>

    )
}

export default ConfermaDati;
