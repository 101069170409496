import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import LoadingMask from "../components/LoadingMask";
import ErrorDialog from "../components/ErrorDialog";
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { check, login } from "../services/apiUtente";





const Login = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const checkLogin = async () => {
        if (localStorage.getItem('token')) {
            try {
                setIsLoading(true)
                let response = await check()
                localStorage.setItem("utente", JSON.stringify(response));
                navigate('/homepage');
                setIsLoading(false)
            }
            catch (err) {
                localStorage.removeItem('utente')
                localStorage.removeItem('token')
                localStorage.removeItem('id_richiesta')
                navigate('/homepage');
            }
        }
    }

    useEffect(() => {
        checkLogin();
    }, []);

    const validateField = (value) => {
        if (!value)
            return true;
        return value.trim() === '';
    };
    const handleClickLogin = async () => {
        try {
            setIsLoading(true)
            let data = {
                username: username,
                password: password
            }
            let response = await login(data)
            let { access_token, ...rest } = response;
            localStorage.setItem("utente", JSON.stringify(rest));
            localStorage.setItem("token", access_token);
            if (rest.flg_reset_password) {
                navigate('/password');
            }
            else {
                navigate('/homepage');
            }
            setIsLoading(false)
        }
        catch (err) {
            setIsLoading(false)
            setErrorMessage(err);
            setShowErrorAlert(true);
        }
    }


    return (
        <Box>
            <TopBar canHome={true} />
            <Stack spacing={4} style={{ paddingLeft: 20, paddingRight: 20 }}>

                <TextField
                    label="Username"
                    value={username}
                    onChange={(event) => {
                        setUsername(event.target.value)
                    }}
                    error={validateField(username)}
                    helperText={validateField(username) ? 'Questo campo è obbligatorio' : ''}
                />
                <FormControl sx={{ m: 1, }} variant="outlined">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                        id="password"
                        label="Password"
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}
                        error={validateField(password)}
                        helperText={validateField(password) ? 'Questo campo è obbligatorio' : ''}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
                    <Button variant="contained" onClick={handleClickLogin}>LOGIN</Button>
                </Box>
            </Stack>
            {
                isLoading &&
                <LoadingMask />
            }
            <ErrorDialog showModal={showErrorAlert} setShowModal={setShowErrorAlert} errore={errorMessage} />
        </Box>

    )
}

export default Login;